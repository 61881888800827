<template>
  <div>
    <section>
      <!-- TODO -->
      <TippCard
        hintID="Stundenabrechnung_Konfiguration_Abrechnungsarten_1_V1"
        text="Abrechnungsarten definieren die Art und Weise, wie Stundenmeldungen abgerechnet werden. Basierend auf Formeln, Einstellungen etc. können unterschiedliche Modalitäten definiert werden."
      >
      </TippCard>
    </section>
    <v-snackbar v-model="helpers.dataImport.snackbar.model" timeout="5000"
      >{{ helpers.dataImport.snackbar.text }}.</v-snackbar
    >
    <section>
      <template>
        <v-container fluid>
          <v-row>
            <v-col cols="12">
              <Subheader
                icon="mdi-folder-multiple"
                title="Abrechnungsgruppen"
                :actions="payrollGroupActions"
              ></Subheader>
              <div class="mt-7">
                <v-data-table
                  :headers="payrollGroupHeaders"
                  :items="payrollGroups"
                  sort-by="sortKey"
                  :sort-desc="false"
                  class="elevation-2"
                  item-key="meta.id"
                  single-expand
                  show-expand
                  :expanded.sync="payrollGroupsExpanded"
                  no-data-text="Keine Abrechnungsgruppen vorhanden"
                >
                  <template v-slot:item.title="{ item }">
                    <div class="d-flex flex-column">
                      <span>
                        <v-icon :color="item.color" class="mr-3"
                          >mdi-circle</v-icon
                        >
                        <span>{{ item.title }}</span>
                        <small v-if="item.description" class="grey--text">
                          <span>{{ item.description }}</span>
                        </small>
                      </span>
                    </div>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-card-text>
                        <v-row dense>
                          <v-col cols="12">
                            <v-card outlined>
                              <v-card-subtitle
                                >Zugewiesene Abrechnungsarten</v-card-subtitle
                              >
                              <div
                                v-for="payrollType in item.payrollTypes.data"
                                :key="payrollType.meta.id"
                              >
                                <v-divider></v-divider>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      {{ payrollType.title }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{
                                      payrollType.shortTitle
                                    }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </div>
                              <div v-if="!item.payrollTypes.data.length">
                                <v-divider></v-divider>
                                <v-card-text class="grey--text"
                                  >Keine Abrechnungsarten
                                  zugewiesen.</v-card-text
                                >
                              </div>
                            </v-card>
                          </v-col>
                          <v-col cols="12">
                            <v-card outlined>
                              <v-card-subtitle
                                >Voreinstellungen für Export</v-card-subtitle
                              >
                              <v-divider></v-divider>
                              <v-card-text class="grey--text"
                                >Keine Voreinstellungen vorhanden.</v-card-text
                              >
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="px-4 pt-0 mt-n2 mb-2"
                        ><v-btn text outlined class="mr-2" disabled
                          ><v-icon left small>mdi-pencil</v-icon
                          >Bearbeiten</v-btn
                        ><v-btn text outlined color="error" disabled
                          ><v-icon left small>mdi-trash-can</v-icon
                          >Löschen</v-btn
                        ></v-card-actions
                      >
                    </td>
                  </template>
                </v-data-table>
              </div>
            </v-col>
            <v-col cols="12">
              <Subheader
                icon="mdi-account-credit-card"
                title="Abrechnungsarten"
                :actions="payrollTypeActions"
              ></Subheader>
              <div class="mt-7">
                <v-data-table
                  :items="payrollTypes"
                  :headers="payrollTypeHeaders"
                  :sort-by="sortBy.toLowerCase()"
                  :sort-desc="sortDesc"
                  group-by="groupByTitle"
                  item-key="meta.id"
                  single-expand
                  show-expand
                  :expanded.sync="payrollTypeExpanded"
                  class="elevation-2"
                  no-data-text="Keine Abrechnungsarten vorhanden"
                >
                  <template v-slot:group.header="{ items, isOpen, toggle }">
                    <th :colspan="payrollTypeHeaders.length">
                      <v-icon @click="toggle" class="mr-1"
                        >{{ isOpen ? "mdi-chevron-up" : "mdi-chevron-down" }}
                      </v-icon>
                      {{ stripPrefix(items[0].groupByTitle) }}
                    </th>
                  </template>
                  <template v-slot:item.title="{ item }">
                    <div class="d-flex flex-column">
                      {{ item.title }}
                      <small class="grey--text">
                        <span>{{ item.shortTitle }}</span>
                      </small>
                    </div>
                  </template>
                  <template v-slot:item.isActive="{ item }">
                    <v-simple-checkbox
                      v-model="item.isActive"
                      color="primary"
                      disabled
                    ></v-simple-checkbox>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length">
                      <v-card-text>
                        <v-row dense>
                          <v-col cols="12">
                            <v-card outlined>
                              <v-card-subtitle>Variablen</v-card-subtitle>
                              <div
                                v-for="variable in item.customVariables"
                                :key="variable.id"
                              >
                                <v-divider></v-divider>
                                <v-list-item>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <code
                                        v-text="variable.options.pseudoId"
                                        class="mr-2"
                                      ></code
                                      >{{ variable.options.label
                                      }}<span v-if="variable.options.isRequired"
                                        >*</span
                                      >
                                    </v-list-item-title>
                                    <v-list-item-subtitle>{{
                                      variable.options.hint
                                    }}</v-list-item-subtitle>
                                  </v-list-item-content>
                                </v-list-item>
                              </div>
                              <div v-if="!item.customVariables.length">
                                <v-divider></v-divider>
                                <v-card-text class="grey--text"
                                  >Keine Variablen vorhanden.</v-card-text
                                >
                              </div>
                            </v-card>
                          </v-col>
                          <v-col cols="12">
                            <v-card outlined>
                              <v-card-subtitle>Formel</v-card-subtitle>
                              <v-divider></v-divider>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>
                                    <code v-text="item.formula"></code>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-card outlined>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    getTimeRoundingTypeText(
                                      item.timeRoundingType
                                    )
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle
                                    >Zeit runden auf</v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-card outlined>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    timestampConverter(
                                      item.validDuration.start.timestamp
                                    )
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle
                                    >Verwendung zulässig
                                    ab</v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-card outlined>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    timestampConverter(
                                      item.validDuration.end.timestamp
                                    )
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle
                                    >Verwendung zulässig
                                    bis</v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-col>
                          <v-col cols="12" sm="6" md="3">
                            <v-card outlined>
                              <v-list-item>
                                <v-list-item-content>
                                  <v-list-item-title>{{
                                    getPaymentOfWagesTypeText(
                                      item.paymentOfWagesType
                                    )
                                  }}</v-list-item-title>
                                  <v-list-item-subtitle
                                    >Auszahlungsfälligkeit</v-list-item-subtitle
                                  >
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                      <v-card-actions class="px-4 pt-0 mt-n2 mb-2"
                        ><v-btn text outlined class="mr-2" disabled
                          ><v-icon left small>mdi-pencil</v-icon
                          >Bearbeiten</v-btn
                        ><v-btn text outlined color="error" disabled
                          ><v-icon left small>mdi-trash-can</v-icon
                          >Löschen</v-btn
                        ></v-card-actions
                      >
                    </td>
                  </template>
                </v-data-table>
              </div>
            </v-col>
            <v-col cols="12">
              <Subheader
                icon="mdi-account-tie"
                title="Abrechnung von Dienststellungen"
              ></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-text>
                    <div class="subtitle-1">
                      Zur Vergütung von Ämtern und Dienststellungen (bspw. bei
                      pauschaler oder spezieller Abrechnung) kann jeder
                      Dienststellung eine Abrechnungsart zugeordnet werden.
                      Inhaber der Dienststellung werden bei der Abrechnung
                      entsprechend berücksichtigt.
                      <br />
                      <v-btn
                        color="primary"
                        depressed
                        class="mt-3"
                        :to="{
                          name: 'member-management-config-duty-positions',
                        }"
                      >
                        <v-icon left> mdi-open-in-app </v-icon>
                        Konfiguration von Dienststellungen öffnen
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
            <v-col cols="12">
              <Subheader
                icon="mdi-account-tie"
                title="Abrechnung von Ehrungen"
              ></Subheader>
              <div class="mt-7">
                <v-card>
                  <v-card-text>
                    <div class="subtitle-1">
                      Zur Vergütung von Ehrungen und Jubiläen (bspw. bei
                      pauschaler oder spezieller Abrechnung) kann jeder Ehrung
                      eine Abrechnungsart zugeordnet werden. Die Verleihung der
                      Ehrung wird in der Abrechnung entsprechend berücksichtigt.
                      <br />
                      <v-btn
                        color="primary"
                        depressed
                        class="mt-3"
                        :to="{
                          name: 'member-management-config-honors',
                        }"
                      >
                        <v-icon left> mdi-open-in-app </v-icon>
                        Konfiguration von Ehrungen öffnen
                      </v-btn>
                    </div>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
    <custom-dialog
      v-if="helpers.dataImport.dialogModel"
      @close="toggleImportDialog()"
      title="Abrechnungsarten importieren"
      text="Importierte Abrechnungsarten stehen nach dem Import sofort zur Verfügung."
      width="m"
      :errorMessage="helpers.dataImport.errorMessage"
      :btnAction="startDataImport"
      :btnDisabled="helpers.dataImport.selectedImportTemplates.length === 0"
      :loading="helpers.dataImport.loading"
      btnText="Auswahl importieren"
    >
      <template v-slot:content>
        <v-form
          v-model="helpers.dataImport.formIsValid"
          @submit.prevent="startDataImport()"
        >
          <v-card outlined class="mt-2">
            <v-data-table
              v-model="helpers.dataImport.selectedImportTemplates"
              :items="helpers.dataImport.importTemplates"
              :headers="helpers.dataImport.tableHeaders"
              :search="helpers.dataImport.search"
              item-key="title"
              show-select
              :sort-by="helpers.dataImport.sortSelector"
              :sort-desc="false"
              checkbox-color="primary"
              disable-pagination
              hide-default-footer
              mobile-breakpoint="0"
              fixed-header
              height="35vh"
              class="mt-1"
              no-results-text="Keine Ergebnisse für Suchbegriff"
            >
              <template v-slot:top>
                <v-card flat>
                  <v-card-subtitle>
                    <v-row>
                      <v-col>
                        <v-text-field
                          v-model="helpers.dataImport.search"
                          label="Nach Abrechnungsarten suchen"
                          prepend-inner-icon="mdi-magnify"
                          dense
                          hide-details="auto"
                          outlined
                          clearable
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <span class="mr-4">Sortieren nach</span>
                        <v-chip-group
                          v-model="helpers.dataImport.sortSelector"
                          mandatory
                          active-class="primary--text"
                        >
                          <v-chip
                            label
                            value="sortKey"
                            class="transparent-background font-weight-medium mr-2 mb-3"
                            ><v-icon left small>mdi-sort-numeric-variant</v-icon
                            >Sortierschlüssel
                          </v-chip>
                          <v-chip
                            label
                            value="title"
                            class="transparent-background font-weight-medium mb-3"
                            ><v-icon left small
                              >mdi-sort-alphabetical-variant</v-icon
                            >Alphabetisch</v-chip
                          >
                        </v-chip-group>
                      </v-col>
                    </v-row>
                  </v-card-subtitle>
                </v-card>
                <v-divider></v-divider>
              </template>
              <template v-slot:[`item.title`]="{ item }">
                <div class="d-flex flex-column">
                  <span
                    class="d-block font-weight-semibold text--primary text-truncate"
                    >{{ item.title }}</span
                  >
                  <small class="grey--text">{{ item.description }}</small>
                </div>
              </template>
            </v-data-table>
          </v-card>
        </v-form>
      </template>
    </custom-dialog>
  </div>
</template>

<script>
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";
import { ACCOUNTING } from "@/store/modules.js";
import {
  GET_PAYROLL_TYPES,
  GET_PAYROLL_GROUPS,
  CREATE_PAYROLL_TYPES,
} from "@/store/action-types.js";
import { ACCOUNTING_CONFIG_BILLING_TYPES_create } from "@/data/permission-types.js";
import { importableRequestTypes } from "@/data/import/importable-payrollTypes.js";

export default {
  name: "accounting-config-tab-payroll-types",
  components: {
    TippCard,
    Subheader,
    SupportTools,
    CustomDialog,
  },
  data() {
    return {
      sortDesc: false,
      sortBy: "sortKey",

      payrollGroupActions: [
        {
          title: "Erstellen",
          icon: "mdi-playlist-plus",
          permission: `${ACCOUNTING_CONFIG_BILLING_TYPES_create}`,
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "accounting-config-tab-payroll-types-payroll-group-new",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          },
        },
      ],
      payrollTypeActions: [
        {
          title: "Erstellen",
          icon: "mdi-playlist-plus",
          permission: `${ACCOUNTING_CONFIG_BILLING_TYPES_create}`,
          actionStyle: "textBtn",
          function: () => {
            this.$router.push({
              name: "accounting-config-tab-payroll-types-payroll-type-new",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          },
        },
      ],

      payrollGroupHeaders: [
        {
          text: "Bezeichnung",
          value: "title",
          align: "start",
        },
        {
          text: "Sortierschlüssel",
          value: "sortKey",
        },
        { text: "", value: "data-table-expand", width: "1%" },
      ],
      payrollTypeHeaders: [
        {
          text: "Abrechnungsart",
          value: "title",
          align: "start",
        },
        {
          text: "Sortierschlüssel",
          value: "sortKey",
        },
        // {
        //   text: "Aktiv",
        //   value: "isActive",
        //   align: "start",
        // },
        { text: "", value: "data-table-expand", width: "1%" },
      ],
      payrollGroupsExpanded: [],
      payrollTypeExpanded: [],
      helpers: {
        dataImport: {
          dialogModel: false,
          loading: false,
          formIsValid: false,
          importTemplates: importableRequestTypes,
          sortSelector: "sortKey",
          errorMessage: "",
          search: "",
          selectedImportTemplates: [],
          tableHeaders: [
            {
              text: "Titel",
              value: "title",
              align: "start",
            },
          ],
          snackbar: { model: false, text: "" },
        },
      },
    };
  },
  computed: {
    payrollGroups() {
      return this.$store.state.accounting.payrollGroups.map((payrollGroup) => {
        return {
          ...payrollGroup,
          // borderColorStyle: this.createBorderColorStyle(payrollGroup.color),
        };
      });
    },
    payrollTypes() {
      const getPayrollGroupSortKey = (groupId) => {
        const group = this.payrollGroups.find(
          (group) => group.meta.id === groupId
        );
        return group ? group.sortKey : "0";
      };
      return this.$store.state.accounting.payrollTypes.map((payrollType) => {
        return {
          ...payrollType,
          groupByTitle:
            getPayrollGroupSortKey(payrollType.group.id) +
            "_" +
            payrollType.group.title,
          borderColorStyle: this.createBorderColorStyle(payrollType.color),
        };
      });
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "payrollGroups", data: this.payrollGroups },
        { title: "payrollTypes", data: this.payrollTypes },
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$store.dispatch(`${ACCOUNTING}${GET_PAYROLL_GROUPS}`, {
        organizationId: this.$route.params.organizationId,
      });
      this.$store.dispatch(`${ACCOUNTING}${GET_PAYROLL_TYPES}`, {
        organizationId: this.$route.params.organizationId,
      });
    },
    stripPrefix(item) {
      return item.substring(item.indexOf("_") + 1);
    },
    createItem() {
      this.$router.push({
        name: "accounting-config-tab-payroll-types-payroll-type-new",
      });
    },
    getTimeRoundingTypeText(value) {
      const timeRoundingTypes = [
        { value: "none", text: "Keine" },
        { value: "roundUp", text: "Aufrunden" },
        { value: "roundDown", text: "Abrunden" },
        { value: "roundToNearest", text: "Auf-/Abrunden" },

        {
          value: "roundToNearestQuarter",
          text: "Auf-/Abrunden auf viertel Stunde",
          disabled: true,
        },
        {
          value: "roundUpToNearestQuarter",
          text: "Aufrunden auf viertel Stunde",
          disabled: true,
        },
        {
          value: "roundDownToNearestQuarter",
          text: "Abrunden auf viertel Stunde",
          disabled: true,
        },

        {
          value: "roundToNearestHalfHour",
          text: "Auf-/Abrunden auf halbe Stunde",
          disabled: true,
        },
        {
          value: "roundUpToNearestHalfHour",
          text: "Aufrunden auf halbe Stunde",
          disabled: true,
        },
        {
          value: "roundDownToNearestHalfHour",
          text: "Abrunden auf halbe Stunde",
          disabled: true,
        },

        {
          value: "roundToNearestHalfHour",
          text: "Auf-/Abrunden auf halbe Stunde",
          disabled: true,
        },
        {
          value: "roundUpToNearestHalfHour",
          text: "Aufrunden auf halbe Stunde",
          disabled: true,
        },
        {
          value: "roundDownToNearestHalfHour",
          text: "Abrunden auf halbe Stunde",
          disabled: true,
        },

        {
          value: "roundToNearestHour",
          text: "Auf-/Abrunden auf volle Stunde",
          disabled: true,
        },
        {
          value: "roundUpToNearestHour",
          text: "Aufrunden auf volle Stunde",
        },
        {
          value: "roundDownToNearestHour",
          text: "Abrunden auf volle Stunde",
          disabled: true,
        },
      ];
      const timeRoundingType = timeRoundingTypes.find(
        (type) => type.value === value
      );
      return timeRoundingType
        ? timeRoundingType.text
        : "Unbekannte Rundungsart";
    },
    getPaymentOfWagesTypeText(value) {
      const paymentTypes = [
        { value: "weekly", text: "Wöchentlich" },
        { value: "biweekly", text: "14-tägig" },
        { value: "monthly", text: "Monatlich" },
        { value: "quarterly", text: "Quartalsweise" },
        { value: "yearly", text: "Jährlich" },
      ];

      const paymentType = paymentTypes.find((type) => type.value === value);
      return paymentType ? paymentType.text : "Unbekannter Zahlungszeitraum";
    },
    toggleImportDialog() {
      this.helpers.dataImport.dialogModel =
        !this.helpers.dataImport.dialogModel;
    },
    startDataImport() {
      if (this.helpers.dataImport.selectedImportTemplates.length > 0) {
        this.helpers.dataImport.loading = true;
        this.$store
          .dispatch(`${ACCOUNTING}${CREATE_PAYROLL_TYPES}`, {
            organizationId: this.$route.params.organizationId,

            payrollTypes: this.helpers.dataImport.selectedImportTemplates,
          })
          .then(() => {
            return this.initialize();
          })
          .then(() => {
            this.toggleImportDialog();
            this.helpers.dataImport.loading = false;
            this.helpers.dataImport.selectedImportTemplates = [];
            this.helpers.dataImport.search = "";
            this.helpers.dataImport.snackbar.model = true;
            this.helpers.dataImport.snackbar.text = "Import erfolgreich";
          })
          .catch((error) => {
            this.helpers.dataImport.loading = false;
            console.log(error);
            this.helpers.dataImport.errorMessage = error.message;
          });
      } else {
        alert(
          "Wähle mindestens eine Abrechnungsart aus, um den Import duchrzuführen."
        );
      }
    },
    routeDetails(item) {
      this.$router.push({
        name: "accounting-config-tab-payroll-types-payroll-type-details",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item,
        },
      });
    },
    createBorderColorStyle(color) {
      const style = "border-left: 6px solid " + color + "!important";
      return style;
    },
    timestampConverter(timestamp) {
      const options = {
        weekday: "short",
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
      };
      return timestamp.toDate().toLocaleDateString("de-DE", options);
    },
  },
};
</script>
