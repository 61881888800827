<template>
  <div>
    <NavigationBar
      titel="Abrechnungsgruppe bearbeiten"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="updateItem"
        ref="form"
        v-model="helpers.formIsValid"
      >
        <payroll-group-editor
          v-model="payrollGroup"
          mode="edit"
        ></payroll-group-editor>
      </v-form>
    </section>
  </div>
</template>

<script>
import { db } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import PayrollGroupEditor from "@/components/accounting/PayrollGroupEditor.vue";

export default {
  name: "scheduling-config-tab-request-type-edit",
  components: {
    NavigationBar,
    PayrollGroupEditor,
  },
  data() {
    return {
      actions: [
        {
          title: "Speichern",
          icon: "mdi-content-save",
          actionStyle: "textBtnSubmit",
          function: this.updateItem,
        },
      ],
      requestType: null,

      helpers: {
        formIsValid: false,
      },
    };
  },
  computed: {},
  created() {
    this.initialize();
  },
  watch: {
    $route: "initialize",
  },
  methods: {
    initialize() {
      db.collection("organizations")
        .doc(this.$route.params.organizationId)
        .collection("payrollGroups")
        .doc(this.$route.params.itemId)
        .get()
        .then((doc) => {
          if (doc.exists) {
            this.payrollGroup = doc.data();
          } else {
            // doc.data() will be undefined in this case
            console.log("No such document!");
          }
        })
        .catch(function (error) {
          console.log("Error getting document:", error);
          return false;
        });
    },
    validate() {
      this.$refs.form.validate();
    },
    updateItem() {
      this.validate();

      if (this.helpers.formIsValid) {
        var res = confirm(
          "Bist Du sicher, dass Du die Veranstaltungsart aktualisieren möchtest und alle bevorstehenden Veranstaltungen angepasst werden sollen?"
        );
        if (res == true) {
          console.log("Fehler");
        }
      } else {
        alert("Prüfe Deine Eingabe und versuche es erneut.");
      }
    },
  },
};
</script>
