<template>
  <div>
    <NavigationBar
      titel="Abrechnungsgruppe anlegen"
      showBackBtn
      :actions="actions"
    ></NavigationBar>
    <section>
      <v-form
        @submit.prevent="createItem"
        ref="form"
        v-model="helpers.formIsValid"
      >
        <payroll-group-editor
          v-model="payrollGroup"
          mode="new"
        ></payroll-group-editor>
      </v-form>
    </section>
    <CustomDialog
      v-if="helpers.actionDialog.model"
      @close="toggleActionDialog()"
      title="Abrechnungsgruppe erstellen"
      text="Die Prüfung der Arbechnungsgruppe muss kann einige Minuten dauern. Bitte warte, bis der Vorgang abgeschlossen ist."
      :loading="helpers.actionDialog.loading"
      :btnAction="createItem"
      :errorMessage="helpers.actionDialog.errorMessage"
      btnText="Verarbeitung starten"
      width="s"
    ></CustomDialog>
  </div>
</template>

<script>
import { functions, Timestamp } from "@/firebase";
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import PayrollGroupEditor from "@/components/accounting/PayrollGroupEditor.vue";
import CustomDialog from "@/components/_systemwide/CustomDialog.vue";

export default {
  name: "accounting-config-tab-payroll-types-payroll-type-new",
  components: {
    NavigationBar,
    PayrollGroupEditor,
    CustomDialog,
  },
  data() {
    return {
      actions: [
        {
          title: "Erstellen",
          icon: "mdi-plus-circle",
          actionStyle: "textBtnSubmit",
          function: this.toggleActionDialog,
        },
      ],
      payrollGroup: {
        title: "",
        shortTitle: "",
        description: "",
        color: "#F44336",
        sortKey: 0,
        type: "default",
      },
      helpers: {
        formIsValid: false,
        actionDialog: {
          model: false,
          loading: false,
          errorMessage: "",
        },
      },
    };
  },
  computed: {
    newSortKey() {
      return this.$store.state.accounting.payrollGroups.length + 1;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.payrollGroup.sortKey = this.newSortKey;
    },
    toggleActionDialog() {
      if (this.helpers.actionDialog.model) {
        this.helpers.actionDialog.model = !this.helpers.actionDialog.model;
      } else {
        this.$refs.form.validate();
        if (this.helpers.formIsValid) {
          this.helpers.actionDialog.model = !this.helpers.actionDialog.model;
        } else {
          alert("Prüfen Deine Eingabe und versuchen es erneut.");
        }
      }
    },
    createItem() {
      this.$refs.form.validate();
      if (this.helpers.formIsValid) {
        this.helpers.actionDialog.errorMessage = "";
        this.helpers.actionDialog.loading = true;

        const callFunction = functions.httpsCallable(
          "accounting-createPayrollGroup"
        );
        return callFunction({
          organizationId: this.$route.params.organizationId,

          payrollGroup: {
            title: this.payrollGroup.title,
            description: this.payrollGroup.description,
            color: this.payrollGroup.color,
            sortKey: this.payrollGroup.sortKey,
            displayType: this.payrollGroup.displayType,
          },
        })
          .then(() => {
            this.$router.push({
              name: "accounting-config-tab-payroll-types",
              params: {
                organizationId: this.$route.params.organizationId,
              },
            });
          })
          .catch((error) => {
            this.helpers.actionDialog.errorMessage = error.message;
            this.helpers.actionDialog.loading = false;
            console.error("Error creating payroll type: ", error);
          });
      } else {
        alert("Prüfen Deine Eingabe und versuchen es erneut.");
      }
    },
    convertToTimestamp(date, time) {
      const input = date + " " + time;
      return Timestamp.fromDate(new Date(input.replace(/-/g, "/")));
    },
  },
};
</script>

<style></style>
