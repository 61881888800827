<template>
  <div>
    <template>
      <v-container>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <Subheader icon="mdi-folder-account" title="Allgemein"></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="8">
                        <v-text-field
                          v-model.trim="payrollGroup.title"
                          :counter="32"
                          label="Bezeichnung"
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" sm="12" md="4">
                        <v-text-field
                          v-model.number="payrollGroup.sortKey"
                          label="Sortierschlüssel"
                          prepend-inner-icon="mdi-sort-numeric-ascending"
                          type="number"
                          hint="Benutzergruppen werden aufsteigend nach ihrem Sortierschlüssel sortiert."
                          persistent-hint
                          required
                          :rules="[rules.required]"
                          outlined
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12">
                        <v-item-group v-model="payrollGroup.type">
                          <v-row class="match-height">
                            <v-col cols="12">
                              <span class="font-weight-medium"
                                >Darstellungsart</span
                              ><br />
                              <small
                                >(insbes. Tabellen in Abrechnungszettel,
                                Auswertungen, XLS-Export)</small
                              >
                            </v-col>
                            <v-col
                              v-for="(type, index) in payrollGroupTypes"
                              :key="index"
                              cols="12"
                              sm="6"
                            >
                              <v-item
                                v-slot="{ active, toggle }"
                                :value="type.id"
                              >
                                <v-card
                                  outlined
                                  @click="toggle"
                                  :style="
                                    active
                                      ? `border: 3px solid #1867c0 !important`
                                      : ''
                                  "
                                >
                                  <v-list-item>
                                    <v-list-item-icon>
                                      <data-avatar
                                        :icon="type.icon"
                                      ></data-avatar>
                                    </v-list-item-icon>
                                    <v-list-item-content>
                                      <v-list-item-title
                                        class="font-weight-medium"
                                      >
                                        {{ type.title }}
                                      </v-list-item-title>
                                      <v-list-item-subtitle>
                                        {{
                                          type.description
                                        }}</v-list-item-subtitle
                                      >
                                    </v-list-item-content>
                                  </v-list-item>
                                </v-card>
                              </v-item>
                            </v-col>
                          </v-row>
                        </v-item-group>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        Farbe wählen
                        <v-color-picker
                          v-model="payrollGroupLocal.color"
                          :swatches="availableColors"
                          hide-inputs
                          hide-canvas
                          hide-sliders
                          show-swatches
                          width="1200"
                          swatches-max-height="150"
                        ></v-color-picker>
                      </v-col>
                      <v-col cols="12" sm="12" md="12">
                        <v-textarea
                          v-model.trim="payrollGroup.description"
                          counter
                          clearable
                          no-resize
                          label="Beschreibung"
                          outlined
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col v-if="mode === 'new'" cols="12">
            <v-alert border="left" colored-border type="info" elevation="2">
              Du musst zunächst eine Abrechnungsgruppe anlegen, um dieser
              Abrechnungsarten zuzuordnen.
            </v-alert>
          </v-col>
          <v-col v-else cols="12">
            <Subheader
              icon="mdi-folder-account"
              title="Zugehörige Abrechnungsarten"
            ></Subheader>
            <div class="mt-7">
              <v-card>
                <v-card-text>
                  <v-container>
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <!-- <v-autocomplete
                          v-model="payrollGroup.members"
                          :items="membershipsInput"
                          chips
                          small-chips
                          outlined
                          hint="Ein Benutzer kann nur einer Benutzergruppe zugehören. Sobald ein Nutzer dieser Benutzergruppe hinzugefügt wird, wird die Zugehörigkeit zu einer anderen Benutzergruppe entfernt! Ein Benutzer muss einer Benutzergruppe angehören und kann daher nicht von einer Benutzergruppe entfernt, sondern nur einer neuen zugewiesen werden."
                          persistent-hint
                          label="Benutzer auswählen"
                          item-text="user.displayName"
                          item-value="user.uid"
                          counter
                          multiple
                        >
                          <template v-slot:item="data">
                            <template v-if="typeof data.item !== 'object'">
                              <v-list-item-content>{{
                                data.item
                              }}</v-list-item-content>
                            </template>
                            <template v-else>
                              <v-avatar color="secondary" size="38">
                                <span class="white--text">{{
                                  initials(data.item.user.displayName)
                                }}</span>
                              </v-avatar>
                              <v-list-item-content class="ml-4">
                                <v-list-item-title>{{
                                  data.item.user.displayName
                                }}</v-list-item-title>
                                <v-list-item-subtitle>{{
                                  data.item.group.title
                                }}</v-list-item-subtitle>
                              </v-list-item-content>
                            </template>
                          </template>
                        </v-autocomplete> -->
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
import Subheader from "@/components/_systemwide/Subheader.vue";
import DataAvatar from "@/components/_systemwide/DataAvatar.vue";
export default {
  name: "payroll-group-editor",
  props: {
    payrollGroup: {},
    membershipsInput: {
      type: Array,
    },
    mode: {
      type: String,
      default: "new",
      required: true,
      validator: function (value) {
        return ["new", "edit"].indexOf(value) !== -1;
      },
    },
  },
  components: {
    Subheader,
    DataAvatar,
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      availableColors: [
        ["#F44336"],
        ["#E91E63"],
        ["#9C27B0"],
        ["#673AB7"],
        ["#3F51B5"],
        ["#2196F3"],
        ["#03A9F4"],
        ["#00BCD4"],
        ["#009688"],
        ["#4CAF50"],
        ["#8BC34A"],
        ["#CDDC39"],
        ["#FFEB3B"],
        ["#FFC107"],
        ["#FF9800"],
        ["#FF5722"],
        ["#795548"],
        ["#607D8B"],
        ["#9E9E9E"],
      ],
      payrollGroupTypes: [
        {
          id: "default",
          title: "Standard",
          description: "",
          icon: "card-bulleted",
        },
        {
          id: "dutyTime",
          title: "Dienstzeit (Einsatzstunden)",
          description: "Einsatzdetails (Einsatzmittel, Position) anzeigen",
          icon: "fire-truck",
        },
        {
          id: "courseTime",
          title: "Lehrgänge/Fortbildungen",
          description: "Lehrgangsdetails (Bezeichnung, Nummer) anzeigen",
          icon: "school",
        },
        {
          id: "dutyPositionTime",
          title: "Ämter",
          description: "",
          icon: "account-tie",
        },
      ],
    };
  },
  model: {
    prop: "payrollGroup",
    event: "payrollGroupChange",
  },
  computed: {
    payrollGroupLocal: {
      get: function () {
        return this.payrollGroup;
      },
      set: function (value) {
        this.$emit("payrollGroupChange", value);
      },
    },
  },
  methods: {},
};
</script>
